import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center py-3" }
const _hoisted_2 = { class: "btn-toolbar" }
const _hoisted_3 = {
  key: 0,
  class: "table-settings mb-4"
}
const _hoisted_4 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = {
  key: 1,
  class: "row"
}
const _hoisted_12 = { class: "col-12 pb-5" }
const _hoisted_13 = { class: "card card-body border-light shadow-sm" }
const _hoisted_14 = { class: "text-center" }
const _hoisted_15 = { class: "fw-bolder" }
const _hoisted_16 = { class: "row justify-content-center text-center py-3" }
const _hoisted_17 = { class: "col-6 col-md-4" }
const _hoisted_18 = { class: "col-6 col-md-4" }
const _hoisted_19 = { class: "col-6 col-md-4" }
const _hoisted_20 = { class: "row text-center py-3" }
const _hoisted_21 = { class: "col-12 col-md-4" }
const _hoisted_22 = { class: "col-12 col-md-4" }
const _hoisted_23 = { class: "col-12 col-md-4" }
const _hoisted_24 = { class: "row text-center py-3" }
const _hoisted_25 = { class: "col-12 col-md-6" }
const _hoisted_26 = { class: "col-12 col-md-6" }
const _hoisted_27 = { class: "col-12 mt-5 mt-md-0" }
const _hoisted_28 = { class: "card card-body border-light shadow-sm" }
const _hoisted_29 = { class: "row text-center py-3" }
const _hoisted_30 = { class: "col-4" }
const _hoisted_31 = { class: "col-4" }
const _hoisted_32 = { class: "col-4" }
const _hoisted_33 = {
  key: 2,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_1, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "d-block" }, [
            _createElementVNode("h2", { class: "h4" }, "Buy Gift Card Transaction Details")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-outline-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
            }, " Reload ")
          ])
        ]),
        (_ctx.data)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-sm btn-success",
                    disabled: _ctx.data.status !== 'PROCESSING',
                    onClick: _cache[1] || (_cache[1] = ($event: any) => {
                _ctx.RequestConfirmation(
                  'Do you really want to approve this transaction?',
                  () => {
                    _ctx.RequestConfirmation(
                      'Sure to approve this transaction? This is an irreversible action',
                      _ctx.ApproveTransaction
                    );
                  }
                )
              })
                  }, " Approve Transaction ", 8, _hoisted_6)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-sm btn-secondary text-nowrap",
                    disabled: _ctx.data.status !== 'SUBMITTED',
                    onClick: _cache[2] || (_cache[2] = ($event: any) => {
                _ctx.RequestConfirmation(
                  'Do you really want to start processing this transaction?',
                  () => {
                    _ctx.RequestConfirmation(
                      'Sure to set transaction status to processing? This is an irreversible action',
                      _ctx.ProcessTransaction
                    );
                  }
                )
              })
                  }, " Process Transaction ", 8, _hoisted_8)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-sm btn-danger text-nowrap",
                    disabled: _ctx.data.status !== 'PROCESSING',
                    onClick: _cache[3] || (_cache[3] = ($event: any) => {
                _ctx.RequestConfirmation(
                  'Do you really want to decline this transaction?',
                  () => {
                    _ctx.RequestConfirmation(
                      'Sure to decline this transaction? This is an irreversible action',
                      _ctx.DeclineTransaction
                    );
                  }
                )
              })
                  }, " Decline Transaction ", 8, _hoisted_10)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.data)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "text-gray" }, "Reference", -1)),
                    _createElementVNode("h5", _hoisted_15, _toDisplayString(_ctx.data.reference), 1)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "text-gray" }, "Status", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.status), 1)
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "text-gray" }, "Rate", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.rate), 1)
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "text-gray" }, "Card Slug", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.card_slug), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "text-gray" }, "UserId", -1)),
                      _createVNode(_component_router_link, {
                        to: `/users/${_ctx.data.User.id}`
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h6", null, _toDisplayString(_ctx.data.User.id), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _cache[10] || (_cache[10] = _createElementVNode("h4", { class: "text-gray" }, "Email", -1)),
                      _createVNode(_component_router_link, {
                        to: `/users/${_ctx.data.User.id}`
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h6", null, _toDisplayString(_ctx.data.User.email), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _cache[11] || (_cache[11] = _createElementVNode("h4", { class: "text-gray" }, "Name", -1)),
                      _createVNode(_component_router_link, {
                        to: `/users/${_ctx.data.User.id}`
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h6", null, _toDisplayString(_ctx.data.User.Profile.first_name) + " " + _toDisplayString(_ctx.data.User.Profile.last_name), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _cache[12] || (_cache[12] = _createElementVNode("h4", { class: "text-gray" }, "Comment", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.description ? _ctx.data.description : "No Description"), 1)
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _cache[13] || (_cache[13] = _createElementVNode("h4", { class: "text-gray" }, "Remark", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.remark ? _ctx.data.remark : "No Remark"), 1)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _cache[17] || (_cache[17] = _createElementVNode("div", { class: "text-center" }, [
                    _createElementVNode("h6", { class: "text-gray" }, "Gift Card Currency")
                  ], -1)),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _cache[14] || (_cache[14] = _createElementVNode("h4", { class: "text-gray" }, "Code", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.FiatCurrency.code), 1)
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _cache[15] || (_cache[15] = _createElementVNode("h4", { class: "text-gray" }, "Name", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.FiatCurrency.name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _cache[16] || (_cache[16] = _createElementVNode("h4", { class: "text-gray" }, "Rate", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.FiatCurrency.rate), 1)
                    ])
                  ])
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_33, _cache[18] || (_cache[18] = [
              _createElementVNode("div", { class: "col-12" }, [
                _createElementVNode("h1", { class: "text-danger text-center" }, "No Data!")
              ], -1)
            ])))
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}